import React from 'react'

export default function Oly101 () {
  return (
    <div>
      <h2>Oly101</h2>
      <div>
        Oly101 is a great foundation course as an introduction to Olympic-Style Weightlifting -
        Snatch and Clean &amp; Jerk for beginning athletes of all sports, especially CrossFitters.
        Learning proper technique from the start is essential to making consistent progress and
        keeping you safe from unnecessary injury. This clinic is a 3 hour session conducted in a
        small group setting of 8 or less people. While this is labeled a &quot;beginner&quot;
        clinic, it is also useful as a refresher for experienced athletes and exceptionally useful
        for young Weightlifting Coaches to practice their craft for teaching others.
      </div>
    </div>
  )
}
