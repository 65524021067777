import React from 'react'
import './coaching-testimonial.css'

const testimonials = [
  {
    name: 'Mike Walters',
    credentials: 'LMT, PTA, EM',
    quote: `Coach Miller is your man if you are serious about lifting and doing it safely. I
          have seen many CrossFitters that are trying to do the Olympic lifts with the injuries to
          show. David may take a little time, but he will get you into it safely – without the blood
          & tears. However, you will definitely put out some sweat!`
  },
  {
    name: 'Jim Hanlon',
    credentials: 'USAW Senior Weightlifting Coach',
    quote: 'I have met very few people that have the technical knowledge, dedication, and enthusiasm that Coach Miller does. He is a true professional and I am proud to call him my friend.'
  },
  {
    name: 'Patrick Cullen-Carroll',
    credentials: 'CSCS, USAW International Coach',
    quote: 'I have known Coach Miller for many years. We have both coached together and taught several USA Weightlifting Certification Courses. David is an excellent coach and teacher. Weightlifters and CrossFitters alike, both new and experienced, will benefit from his coaching expertise.'
  },
  {
    name: 'Artie Drechsler',
    credentials: 'USAW International Coach & Author of The Weightlifting Encyclopedia',
    quote: 'In addition to being a very experienced coach who has working with many top athletes, David Miller is one our most active coaching course instructors. No doubt those who train with him will come away having learned things that will really improve their lifting.'
  },
  {
    name: 'Leo Totten',
    credentials: 'Head Coach East Coast Gold, USAW International Coach',
    quote: 'David Miller has been in the lifting game for many years and is one of the best teachers/coaches of the Olympic lifts around.'
  }
]

const randomElement = A => A[Math.floor(Math.random() * A.length)]

export const randomTestimonial = () => randomElement(testimonials)

export default function CoachingTestimonial ({ name, credentials, quote }) {
  return (
    <div>
      <div className='seminar-quote'>
        <p className='semi-quote-text'>
          &quot;
          {quote}
          &quot;
        </p>
        <h4 style={{ margin: '0', fontSize: '0.75em' }}>{name}</h4>
        <p style={{ margin: '0', fontSize: '0.75em' }}>{credentials}</p>
      </div>
    </div>
  )
}
