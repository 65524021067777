import React from 'react'
import PropTypes from 'prop-types'
import RegistrationItem from './registration-list-item'

import './registration-list.scss'
import { dateString } from '../lib/months'

const whener = s => s.custom_date_time || `${s.time} ${dateString(s.date)}`

const seminarFactory = (seminar, when) => (
  <RegistrationItem
    key={seminar.seminar_id}
    title={seminar.name}
    when={when || [whener(seminar)]}
    price={seminar.price}
    skus={[seminar.sku]}
  />
)

const combinedFactory = (seminars, combined) => {
  const ids = combined.combined.split(',')
  const c = seminars.filter(s => ids.includes(s.seminar_id))
  const when = c.map(whener)

  return seminarFactory(combined, when)
}

export default function RegistraionList ({ seminar }) {
  const seminars = seminar.seminar.filter(s => !s.combined && s.date)
  const combined = seminar.seminar.filter(s => s.combined)
  return (
    <div className='regi-list-container'>
      {seminars.map(s => seminarFactory(s))}
      {combined.map(c => combinedFactory(seminars, c))}
    </div>
  )
}

RegistraionList.propTypes = {
  seminar: PropTypes.shape({
    date: PropTypes.string.isRequired
  }).isRequired
}
