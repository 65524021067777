import React from 'react'

export default function AboutZurich () {
  return (
    <div>
      <h2>Intermediate Weightlifting in Zürich</h2>
      <div>
        This is a 2 hour seminar specifically covering the finer points of Olympic-Style
        Weightlifting, tailored for the athlete who has experience practicing Snatch and Clean &amp;
        Jerk. We'll begin with a short 15 minute discussion based in bio-mechanics intended to bring
        us all onto "the same page" of vocabulary and basic principles. After a brief warm-up,
        everyone will be instructed through fundamental movement mechanics that then lead to a short
        workout. This workout breaks down the techniques and drills necessary for all athletes to
        resolve their individual movement deficiencies. My goal is to send each of you away after 2
        hours with homework to continue improving your technique.
      </div>
    </div>
  )
}
