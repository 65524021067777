const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const getMonth = date => months[date.getMonth()]

export const seminarDate = (seminar) => {
  const d1 = seminar.date
    .substr(0, 10)
    .split('-')
    .map(n => Number(n))
  return new Date(d1[0], d1[1] - 1, d1[2])
}

export const dateString = (date) => {
  if (!date.getDay) {
    date = seminarDate({ date })
  }
  return `${days[date.getDay()]} ${monthsShort[date.getMonth()]} ${date.getDate()}`
}
