import React from 'react'

export default function Intermediate () {
  return (
    <div>
      <h2>Intermediate Weightlifting</h2>
      <div>
        This is an all day seminar specifically covering the finer points of Olympic-Style
        Weightlifting, tailored for the athlete who has experience practicing Snatch and Clean &amp;
        Jerk. The morning session begins with a short 45 minute lecture based in bio-mechanics
        intended to bring us all onto &quot;the same page&quot; of vocabulary and basic principles.
        After a brief warm-up, everyone will be instructed through fundamental movement mechanics
        that then lead to the AM workout. This workout breaks down the techniques and drills
        necessary for all athletes to resolve their individual movement deficiencies. Video will be
        taken, which we&apos;ll review after lunch. Utilizing the information from our video
        analysis, the afternoon workout is planned to address additional technical flaws by
        practicing more drills and exercises for attendees to hone their practice of Weightlifting
        well beyond the seminar.
      </div>
    </div>
  )
}
