import React from 'react'
import PropTypes from 'prop-types'

import './registration-list-item.scss'
import StripeButton from './stripe-button'

export default function RegistrationItem ({
  title, when, price, skus
}) {
  return (
    <div className='regi-list-item-container'>
      <div className='regi-list-item-row'>
        <div>
          <div className='list-item-line1'>{title}</div>
          {when.map(w => <div className='list-item-line2'>{w}</div>)}
        </div>
        <div className='price-align'>{price}</div>
        <StripeButton skus={skus} successUrl='/thank-you-register' className='seminars-list-btn'>REGISTER</StripeButton>
      </div>
    </div>
  )
}

RegistrationItem.propTypes = {
  price: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  when: PropTypes.arrayOf(PropTypes.string).isRequired,
  skus: PropTypes.arrayOf(PropTypes.string).isRequired
}
