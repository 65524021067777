import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Oly101 from '../components/seminar_types/oly101'
import Intermediate from '../components/seminar_types/intermediate'
import AboutZurich from '../components/seminar_types/zurich'
import RegistrationList from '../components/registration-list'

import './seminar.scss'
import arrowBack from '../images/arrow_back_black_36x36.png'
import locationIcon from '../images/location_black_18x18.png'
import { getMonth, seminarDate } from '../lib/months'
import CoachingTestimonial, { randomTestimonial } from '../components/coaching-testimonial'

export default function SeminarTemplate ({ data }) {
  const seminar = data.seminarsYaml
  const date = seminarDate(seminar)
  const month = getMonth(date)
  const when = `${month} ${date.getDate()}`
  const testimonial = randomTestimonial()
  const hasOly101 = seminar.seminar.filter(s => s.type === 'oly101').length > 0
  const hasInt = seminar.seminar.filter(s => s.type === 'intermediate').length > 0
  const hasZurich = seminar.seminar.filter(s => s.type === 'zurich').length > 0

  return (
    <Layout>
      <SEO title={`${seminar.title} Weightlifting Seminar`} />
      <div className='background-body'>
        <div style={{ maxWidth: '90%', margin: 'auto' }}>
          <div className='flex-row' style={{ paddingTop: '32px' }}>
            <Link to='/#seminars' style={{ marginRight: '32px' }}>
              <img src={arrowBack} alt='arrow_back' height='36' width='36' />
            </Link>
            <div className='seminar-days-month'>{when}</div>
          </div>
          <h1 className='seminar-pg-title'>{seminar.title}</h1>
          <div className='flex-row semi-flex-row2col semi-content-reverse'>
            <div className='flex-col content-left-col'>
              {hasOly101 ? <Oly101 /> : null}
              {hasInt ? <Intermediate /> : null}
              {hasZurich ? <AboutZurich /> : null}
            </div>
            <div className='flex-col content-rigth-col'>
              <div className='location-container'>
                <div>
                  <img
                    src={locationIcon}
                    height='18'
                    width='18'
                    alt='location_icon'
                    style={{ paddingRight: '16px' }}
                  />
                </div>
                <div>
                  <h4 style={{ margin: '0' }}>{seminar.location_name}</h4>
                  <p style={{ margin: '0' }}>
                    {seminar.addr.line1}
                    <br />
                    {seminar.addr.line2}
                    <br />
                    <span style={{ fontWeight: 'bold' }}>{seminar.addr.phone}</span>
                  </p>
                </div>
              </div>
              <div style={{ margin: '3%', textAlign: 'center' }}>
                <iframe
                  title='gmap'
                  src={seminar.addr.gmap}
                  width='400'
                  height='241'
                  frameBorder='0'
                  style={{ border: 0, maxWidth: '100%' }}
                  allowFullScreen
                />
              </div>
              <CoachingTestimonial {...testimonial} />
            </div>
          </div>
          {date > new Date() ? (
            <>
              <h1>Registration</h1>
              <RegistrationList seminar={seminar} />
            </>
          ) : (
            <div
              style={{
                fontWeight: 500,
                margin: '25px 0 50px',
                textAlign: 'center',
                fontSize: '1.5em'
              }}
            >
              Registration for this event has closed.
            </div>
          )}
        </div>
        <div>&nbsp;</div>
      </div>
    </Layout>
  )
}
export const seminarQuery = graphql`
  query SeminarByPath($path: String!) {
    seminarsYaml(path: { eq: $path }) {
      title
      location_name
      date
      addr {
        line1
        line2
        phone
        gmap
      }
      seminar {
        seminar_id
        type
        name
        price
        date
        time
        custom_date_time
        sku
        combined
      }
    }
  }
`
